  'use strict';
  /* global $, document, window */
  $('.slider').each(function() {
    var $slides = $(this).find('.slide');
    var bulletArray = [];
    var currentIndex = 0;
    var onScrollDisable = false;
    var timeout;
    function move(newIndex) {
      if ($(document).width() < 768) {
        advance();
      }
      if (newIndex > $slides.length - 1) {
        newIndex = $slides.length - 1;
      }
      if (newIndex < 0) {
        newIndex = 0;
      }
      $slides.each(function() {
        if (parseInt($(this).attr('data-id'), 10) < newIndex) {
          $(this).addClass('isFaded');
        } else {
          $(this).removeClass('isFaded');
        }
      });
      $slides.removeClass('isActive').eq(newIndex).addClass('isActive');
      bulletArray[currentIndex].removeClass('active');
      bulletArray[newIndex].addClass('active');
      currentIndex = newIndex;
    }
    function nextSlide() {
      move(currentIndex + 1);
    }
    function prevSlide() {
      move(currentIndex - 1);
    }
    function advance() {
      clearTimeout(timeout);
      timeout = setTimeout(function() {
        if (currentIndex < ($slides.length - 1)) {
          nextSlide();
        } else {
          move(0);
        }
      }, 3800);
    }
    $.each($slides, function(index) {
      var $button = $('<a class="slide_btn"><span></span></a>');
      $(this).attr('data-id', index);
      if (index === currentIndex) {
        $button.addClass('active');
      }
      $button.on('click', function() {
        move(index);
      }).appendTo('.slide_buttons');
      bulletArray.push($button);
    });
    // scroll
    var elem = document.getElementById('sldr');
    if (elem.addEventListener) {
      if ('onwheel' in document) {
        // FF17+, Ch31+
        elem.addEventListener('wheel', onWheel);
      } else if ('onmousewheel' in document) {
        // устаревший вариант события IE
        elem.addEventListener('mousewheel', onWheelReverse);
      } else {
        // Firefox < 17
        elem.addEventListener('MozMousePixelScroll', onWheel);
      }
    } else {
      // IE8-
      elem.attachEvent('onmousewheel', onWheel);
    }
    function onWheel(e) {
      if (currentIndex < $slides.length) {
        e.preventDefault();
      }
      if (onScrollDisable) {
        return;
      }
      setTimeout(function() {
        onScrollDisable = false;
      }, 1500);
      e = e || window.event;
      var delta = e.deltaY || e.detail || e.wheelDelta;
      if (delta > 0) {
        if (currentIndex + 1 === $slides.length) {
          $('body,html').animate({
            scrollTop: $('.section_who').offset().top
          }, 1000);
        } else {
          nextSlide();
          onScrollDisable = true;
        }
      } else {
        prevSlide();
        onScrollDisable = true;
        $('body,html').animate({
          scrollTop: 0
        }, 1000);
      }
      onScrollDisable = true;
    }
    // this is for ie
    function onWheelReverse(e) {
      if (currentIndex < $slides.length) {
        e.preventDefault();
      }
      if (onScrollDisable) {
        return;
      }
      setTimeout(function() {
        onScrollDisable = false;
      }, 1500);
      e = e || window.event;
      var delta = e.deltaY || e.detail || e.wheelDelta;
      if (delta < 0) {
        if (currentIndex + 1 === $slides.length) {
          $('body,html').animate({
            scrollTop: $('.section_who').offset().top
          }, 1000);
        } else {
          nextSlide();
          onScrollDisable = true;
        }
      } else {
        prevSlide();
        onScrollDisable = true;
        $('body,html').animate({
          scrollTop: 0
        }, 1000);
      }
      onScrollDisable = true;
    }
    // swipe
    $(document).on('pagecreate', 'body', function() {
      $('#sldr').on('swiperight', function(e) {
        e.preventDefault();
        prevSlide();
      });
      $('#sldr').on('swipeleft', function(e) {
        e.preventDefault();
        nextSlide();
      });
    });
    move(0);
    if ($(document).width() < 768) {
      move(4);
      advance();  
    }
  });
