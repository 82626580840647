/*!
 *
 *  Web Starter Kit
 *  Copyright 2015 Google Inc. All rights reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License
 *
 */
/* eslint-env browser */
(function () {
  'use strict';

  // Check to make sure service workers are supported in the current browser,
  // and that the current page is accessed from a secure origin. Using a
  // service worker from an insecure origin will trigger JS console errors. See
  // http://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
  var isLocalhost = Boolean(window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );

  if ('serviceWorker' in navigator &&
    (window.location.protocol === 'https:' || isLocalhost)) {
    navigator.serviceWorker.register('service-worker.js')
      .then(function (registration) {
        // updatefound is fired if service-worker.js changes.
        registration.onupdatefound = function () {
          // updatefound is also fired the very first time the SW is installed,
          // and there's no need to prompt for a reload at that point.
          // So check here to see if the page is already controlled,
          // i.e. whether there's an existing service worker.
          if (navigator.serviceWorker.controller) {
            // The updatefound event implies that registration.installing is set:
            // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-container-updatefound-event
            var installingWorker = registration.installing;

            installingWorker.onstatechange = function () {
              switch (installingWorker.state) {
                case 'installed':
                  // At this point, the old content will have been purged and the
                  // fresh content will have been added to the cache.
                  // It's the perfect time to display a "New content is
                  // available; please refresh." message in the page's interface.
                  break;

                case 'redundant':
                  throw new Error('The installing ' +
                    'service worker became redundant.');

                default:
                  // Ignore
              }
            };
          }
        };
      }).catch(function (e) {
        console.error('Error during service worker registration:', e);
      });
  }

  // Your custom JavaScript goes here
  /* global $, document, window */
  function parallaxBox(box, k, z) {
    z = typeof z !== 'undefined' ? z : 0;
    if ($(window).width() < 600) {
      z = 0;
      if (k > 0.2) {
        k = 0.1;
      }
    }
    if (!$(box).length) {
      return;
    }
    var scrollTop = $(window).scrollTop();
    var elementOffset = $(box).offset().top;
    var distance = (elementOffset - scrollTop);
    $(box).css('transform', 'translate3d(0, ' + ((distance * k) - z) + 'px, 0)');
  }

  $(window).load(function () {
    parallaxBox('.fixed_wrapper img', 0.3, 250);
    parallaxBox('.parallax_low', 0.1);
    parallaxBox('.parallax_fast', 0.16);
    if ($(document).width() > 768) {
      parallaxBox('.parallax_background', -0.06);
      parallaxBox('.parallax_title_wrapper .title', 0.06, 70);
    }
    $('a').each(function () {
      $(this).attr("rel", "external");
    });
    Waypoint.refreshAll();
  });
  $(document).ready(function () {
    var timeFromStart = performance.now();
    var timeOut = 0;
    if (timeFromStart > 5000) {
      timeOut = 0;
    } else {
      timeOut = 5000 - timeFromStart;
    }
    setTimeout(function () {
      $('.preloader').hide('fast');
      $('.preloader_wrapper').addClass('hide');
    }, timeOut);

    $('div.ui-loader').hide();
    $('.col_text p').css('opacity', 1);
    $('.mobile_menu').on('click', function () {
      $(this).toggleClass('checked');
      $('.navigation_wrapper').toggleClass('show');
    });
    //tarrif plans button
    $('.section_choise .select_button_wrapper .select_button_monthly').click(function (e) {
      e.preventDefault();
      $('.section_choise .select_button_wrapper .select_button_yearly').removeClass('active');
      $(this).addClass('active');
      $('.price_block_wrapper .price_block.yearly, .btn.yearly').removeClass('show');
      $('.price_block_wrapper .price_block.monthly, .btn.monthly').addClass('show');
    });
    //tarrif plans button
    $('.section_choise .select_button_wrapper .select_button_yearly').click(function (e) {
      e.preventDefault();
      $('.section_choise .select_button_wrapper .select_button_monthly').removeClass('active');
      $(this).addClass('active');
      $('.price_block_wrapper .price_block.monthly, .btn.monthly').removeClass('show');
      $('.price_block_wrapper .price_block.yearly, .btn.yearly').addClass('show');
    });
    // read more button
    $('.read_more_button').click(function (e) {
      e.preventDefault();
      $('.hidden_text').slideDown();
      $('.read_more_button').hide();
    });
  });

  $(document).scroll(function () {
    parallaxBox('.fixed_wrapper img', 0.3, 250);
    parallaxBox('.parallax_low', 0.1);
    parallaxBox('.parallax_fast', 0.16);
    if ($(document).width() > 768) {
      parallaxBox('.parallax_background', -0.06);
      parallaxBox('.parallax_title_wrapper .title', 0.06, 70);
      // sticky main menu 
      if ($(document).scrollTop() > 70) {
        $('.header').addClass('sticky');
      } else {
        $('.header').removeClass('sticky');
      }
    }
    // sticky table header 
    if (($(document).width() > 1024) && ($('section').hasClass('compare-table'))) {
      if ($(document).scrollTop() > ($('.compare-table').offset().top) + 50) {
        $('.table_wrapper').addClass('fixed');
      } else {
        $('.table_wrapper').removeClass('fixed');
      }
    }
  
  });

  if ($(document).width() > 768) {
    var continuousElements = document.getElementsByClassName('animate');
    $('.animate').wrap('<div class = "aniamte_wrapper_wrapper"></div>').wrap('<div class = "aniamte_wrapper"></div>');
    for (var i = 0; i < continuousElements.length; i++) {
      new Waypoint({
        element: continuousElements[i],
        handler: function (direction) {
          var height = this.element.offsetHeight;
          var width = this.element.offsetHeight;
          if (direction === 'up') {
            $(this.element).parent().css({
              'transform': 'translate3d(200px, 100px,0) rotate(-40deg)',
              'opacity': 0
            });
            $(this.element).parent().parent().css({
              'transform': 'rotate(40deg)',
              'opacity': 0,
            });
          }
          if (direction === 'down') {
            $(this.element).parent().css({
              'transform': 'translate3d(0, 0, 0)',
              'opacity': 1
            });
            $(this.element).parent().parent().css({
              'transform': 'rotate(0deg)',
              'opacity': 1,
            });
          }
        },
        offset: function () {
          return Waypoint.viewportHeight() + 150;
        }
      });
      new Waypoint({
        element: continuousElements[i],
        handler: function (direction) {
          var height = this.element.offsetHeight;
          var width = this.element.offsetHeight;
          if (direction === 'down') {
            $(this.element).css({
              'transform': 'translate3d(' + 0 + 'px,' + -100 + 'px, 0)',
              'opacity': 0
            });
          }
          if (direction === 'up') {
            $(this.element).css({
              'transform': 'translate3d(0, 0, 0)',
              'opacity': 1
            });
          }
        },
        offset: function () {
          return -this.element.clientHeight + 30;
        }
      });
    }
    var continuousElements = document.getElementsByClassName('fade_in_row');
    for (var i = 0; i < continuousElements.length; i++) {
      new Waypoint({
        element: continuousElements[i],
        handler: function (direction) {
          $(this.element).addClass('show_icons');
        },
        offset: '90%'
      });
    }
  } else {
    var continuousElements = document.getElementsByClassName('icon_block');
    for (var i = 0; i < continuousElements.length; i++) {
      new Waypoint({
        element: continuousElements[i],
        handler: function (direction) {
          $(this.element).css('opacity', 1);
        },
        offset: '90%'
      });
    }
  }
  $('[data-toggle="tooltip"]').tooltip({
    position: {
      my: "center bottom-20",
      at: "center center"
    }
  });

})();